import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import SampleSite, { SampleSiteList } from './SampleSiteClass';
import SamplingSpec, { SamplingSpecList } from './SamplingSpecClass';
import SoilCore, { SoilCoreList } from './SoilCoreClass';
import SampleBox, { SampleBoxList } from './SampleBoxClass';
import { flatten } from 'lodash';

import { to_kml, to_feature, setBarcode } from '../db_ops/sample_ops';
import SamplingTypes from './SamplingTypesDatatype';
import { IKMLElement } from '../kml';
import Mission from './MissionClass';
import { ISample, SampleBoxDeSerialized } from './types';
import { cleanSet, distanceLatLon } from '../utils';
import { Coordinate } from 'ol/coordinate';
import SampleKmlLoader from '../services/mission-loading/original/from-kml/SampleKmlLoader';

export default class Sample extends ObjectClassGenerator<Sample>('Sample') implements ISample {
  // attributes
  #sample_id: string;
  #sample_type: SamplingTypes;
  #order: number;
  #previous_order: number;
  #bag_id?: string;
  // unfortunately everything relies on this really being the barcT extends DataObjectde scan time.
  // in an effort to remove that assocation, we will add two new attributes
  // - scanned_at
  // - sampled_at
  // for now, pulled_at will still be the scan time. But we may be able to change it to
  // be the actual sampled time if we can determine there won't be bad downstream effects.
  // because we don't actually have a good "core" sampled at time, we won't set that right now
  #sampled_at: number;
  #scanned_at: number;
  #pulled_at: number;

  // relationships
  #SampleSite_id?: number;
  #SamplingSpec_id?: number;
  #SampleBox_id?: number;

  static tableName = 'Sample';

  constructor(state: Partial<Sample> = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Sample, 'sample_id');
    this.publishAttribute(Sample, 'sample_type');
    this.publishAttribute(Sample, 'order');
    this.publishAttribute(Sample, 'previous_order');
    this.publishAttribute(Sample, 'bag_id');
    this.publishAttribute(Sample, 'sampled_at');
    this.publishAttribute(Sample, 'scanned_at');
    this.publishAttribute(Sample, 'pulled_at');
    this.publishAttribute(Sample, 'SampleSite_id');
    this.publishAttribute(Sample, 'SamplingSpec_id');
    this.publishAttribute(Sample, 'SampleBox_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Sample> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#sample_id = state.sample_id || '';
    this.#sample_type = state.sample_type || 0;
    this.#order = state.order || 0;
    this.#previous_order = state.previous_order || 0;
    this.#bag_id = state.bag_id || '';
    this.#sampled_at = state.sampled_at || 0.0;
    this.#scanned_at = state.scanned_at || 0.0;
    this.#pulled_at = state.pulled_at || 0.0;
    this.#SampleSite_id = state.SampleSite_id;
    this.#SamplingSpec_id = state.SamplingSpec_id;
    this.#SampleBox_id = state.SampleBox_id;
  }

  dispose() {
    const samplesite = this.getSampleSite();
    if (samplesite) {
      this.SampleSite_id = undefined;
    }
    const samplingspec = this.getSamplingSpec();
    if (samplingspec) {
      this.SamplingSpec_id = undefined;
    }
    for (const soilcore of this.getSoilCores()) {
      soilcore.Sample_id = undefined;
      soilcore.dispose();
    }
    const samplebox = this.getSampleBox();
    if (samplebox) {
      this.SampleBox_id = undefined;
    }

    Sample.delete(this.instance_id);
  }

  set sample_type(value) {
    this.#sample_type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sample_type() {
    return this.#sample_type;
  }

  set order(value) {
    this.#order = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get order() {
    return this.#order;
  }

  set previous_order(value) {
    this.#previous_order = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get previous_order() {
    return this.#previous_order;
  }

  set bag_id(value) {
    this.#bag_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get bag_id() {
    return this.#bag_id;
  }

  set sampled_at(value) {
    this.#sampled_at = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sampled_at() {
    return this.#sampled_at;
  }

  set scanned_at(value) {
    this.#scanned_at = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get scanned_at() {
    return this.#scanned_at;
  }

  set pulled_at(value) {
    this.#pulled_at = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pulled_at() {
    return this.#pulled_at;
  }

  set change_reason(value) {
    let inst: SampleSite;
    if (Boolean((inst = this.getSampleSite()))) {
      inst.change_reason = value;
    }
  }

  get change_reason() {
    return this.getSampleSite()?.change_reason || '';
  }

  get skipped_or_deleted() {
    return this.change_type === 'Delete' || this.change_type === 'Skip';
  }

  set change_type(value) {
    let inst: SampleSite;
    if (Boolean((inst = this.getSampleSite()))) {
      inst.change_type = value;
    }
  }

  get change_type() {
    return this.getSampleSite()?.change_type || 'None';
  }

  set sample_site_source(value) {
    let inst: SampleSite;
    if (Boolean((inst = this.getSampleSite()))) {
      inst.sample_site_source = value;
    }
  }

  get sample_site_source() {
    return this.getSampleSite()?.sample_site_source || 'Unknown';
  }

  get mission_name() {
    return this.getSampleSite()?.mission_name;
  }

  get last_modified() {
    return this.getSampleSite()?.last_modified;
  }

  set last_modified(value) {
    let inst: SampleSite;
    if (Boolean((inst = this.getSampleSite()))) {
      inst.last_modified = value;
    }
  }

  get sample_id() {
    return this.#sample_id;
  }

  set sample_id(value: string) {
    this.#sample_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get spec_id() {
    let inst: SamplingSpec | undefined;
    return Boolean((inst = this.getSamplingSpec())) ? inst!.spec_id : undefined;
  }

  get box_uid() {
    let inst: SampleBox | undefined;
    if (Boolean((inst = this.getSampleBox()))) {
      return inst?.uid;
    } else {
      return undefined;
    }
  }

  set SampleSite_id(value) {
    if (this.#SampleSite_id) {
      const relateObj = SampleSite.get(this.#SampleSite_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Sample', this.instance_id);
      }
    }
    this.#SampleSite_id = value;
    if (value) {
      const relateObj = SampleSite.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Sample', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get SampleSite_id() {
    return this.#SampleSite_id;
  }

  // @ts-ignore
  getSampleSite() {
    return SampleSite.get(this.SampleSite_id)!;
  }

  set SamplingSpec_id(value) {
    if (this.#SamplingSpec_id) {
      const relateObj = SamplingSpec.get(this.#SamplingSpec_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Sample', this.instance_id);
      }
    }
    this.#SamplingSpec_id = value;
    if (value) {
      const relateObj = SamplingSpec.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Sample', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get SamplingSpec_id() {
    return this.#SamplingSpec_id;
  }

  getSamplingSpec() {
    return SamplingSpec.get(this.SamplingSpec_id);
  }

  async removeFromPath() {
    const mission = this.getSampleSite()?.getMission();
    if (!mission) {
      return;
    }
    this.#previous_order = this.#order;
    this.#order = -1;
    for (const wp of mission.getWaypoints()) {
      if (wp.getCorePoint()?.getSoilCore()?.Sample_id === this.instance_id) {
        wp.dispose();
      }
    }
    this.syncToDB();
  }

  getSoilCores() {
    if (this._refs && this._refs.SoilCore) {
      return new SoilCoreList(
        ...Array.from(this._refs.SoilCore)
          .map((id) => SoilCore.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const soilcores = SoilCore.query((sel) => sel && sel.Sample_id === this.instance_id);
      for (const soilcore of soilcores) {
        soilcore.Sample_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SoilCoreList(...soilcores);
    }
  }

  allCoresPulled() {
    return this.getSoilCores().every((sel) => sel.pulled);
  }

  getPulledSoilCores() {
    return this.getSoilCores().filter((sel) => sel.pulled);
  }

  hasCoresPulled(): boolean {
    return this.getPulledSoilCores().length > 0;
  }

  getNextSoilCoreTarget(robotOrArmPosition: Coordinate) {
    // order soil cores by waypoint order
    const cores = this.getSoilCores().toSorted((a, b) => a.core_number - b.core_number);

    let targetCore: SoilCore | undefined = undefined;

    const sampleHasPulledCores = this.hasCoresPulled();
    let firstCorePulled = false;

    // If a sample has at least one core that has already been pulled,
    // then our target is determined - it is a next core down the line.
    if (sampleHasPulledCores) {
      firstCorePulled = cores[0].pulled;

      // if the first core is not pulled, then we'll find the next available core starting from the back.
      if (!firstCorePulled) {
        cores.reverse();
      }

      targetCore = cores.find((core) => !core.pulled);
    }
    // If no cores have been pulled yet, then we allow coring from either end of the core line:
    // we choose the target edge core by the proximity to the current position of the robot.
    else {
      const edgeCores = this.getEdgeUnpulledSoilCores();

      if (edgeCores.length === 0) {
        return undefined;
      }

      if (edgeCores.length === 1) {
        return edgeCores[0];
      }

      // Get the closest edge core to the origin
      targetCore = edgeCores.reduce((prev, curr) => {
        const prevDistance = distanceLatLon(robotOrArmPosition[0], robotOrArmPosition[1], prev.lat, prev.lon, 'Feet');

        const currDistance = distanceLatLon(robotOrArmPosition[0], robotOrArmPosition[1], curr.lat, curr.lon, 'Feet');

        return prevDistance < currDistance ? prev : curr;
      });
    }

    return targetCore;
  }

  getEdgeUnpulledSoilCores(): SoilCore[] {
    const soilcores = this.getSoilCores();
    if (soilcores.length === 0) {
      return [];
    }

    // Order soil cores by waypoint order
    soilcores.sort((a, b) => a.waypoint_index - b.waypoint_index);

    // Get the cores that don't already have a pulled_lat and pulled_lon value
    const unpulled = soilcores.filter((sel) => !sel.pulled);
    if (!unpulled.length) {
      return [];
    }

    if (unpulled.length === 1) {
      return [unpulled[0]];
    }

    // Return the edge cores
    return [unpulled[0], unpulled[unpulled.length - 1]];
  }

  set SampleBox_id(value) {
    if (this.#SampleBox_id) {
      const relateObj = SampleBox.get(this.#SampleBox_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Sample', this.instance_id);
      }
    }
    this.#SampleBox_id = value;
    if (value) {
      const relateObj = SampleBox.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Sample', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get SampleBox_id() {
    return this.#SampleBox_id;
  }

  getSampleBox() {
    return SampleBox.get(this.SampleBox_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Sample.query(
      (sel: Sample) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.last_modified === this.last_modified &&
        sel.sample_id === this.sample_id &&
        sel.sample_type === this.sample_type,
    );
    for (const dup of id1_duplicates) {
      problems.push(`Duplicate sample found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`sample: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getSampleSite()) {
      problems.push(
        `sample: Could not find sample site instance across unconditional relationship R19: ${this.instance_id}`,
      );
    }
    if (!this.getSamplingSpec()) {
      problems.push(
        `sample: Could not find sampling spec instance across unconditional relationship R21: ${this.instance_id}`,
      );
    }
    return problems;
  }

  resetPulled() {
    this.getSoilCores().forEach((core) => core.resetPulled());

    // TODO delete rogo field ops cores?
  }

  to_kml({ use_pulled_locations = false } = {}) {
    return to_kml.bind(this)({ use_pulled_locations });
  }

  static async load_kml(point: IKMLElement, mission: Mission, deSerializeBoxes: SampleBoxDeSerialized[]) {
    const sampleKmlLoader = new SampleKmlLoader(mission, deSerializeBoxes);

    return await sampleKmlLoader.load(point);
  }

  calculatePulledCoresCentroid(): Coordinate {
    const pulledCores = this.getPulledSoilCores();
    if (!pulledCores.length) {
      return [0, 0];
    }

    return pulledCores
      .reduce(
        (accumulator, core) => {
          if (core.pulled_lat && core.pulled_lon) {
            accumulator[0] += core.pulled_lat;
            accumulator[1] += core.pulled_lon;
          }
          return accumulator;
        },
        [0, 0],
      )
      .map((sel) => sel / pulledCores.length);
  }

  to_feature(showBasedOnPulledCoreLocations: boolean) {
    return to_feature.bind(this)(showBasedOnPulledCoreLocations);
  }

  setBarcode(barcode: string | undefined, boxInstanceId?: number) {
    setBarcode.bind(this)(barcode, boxInstanceId);
  }

  clearBarcode() {
    if (this.hasBarcode()) {
      this.setBarcode(undefined, this.SampleBox_id);
    }
  }

  hasBarcode() {
    return Boolean(this.bag_id);
  }

  getBarCode(): string | undefined {
    return this.bag_id;
  }

  getMission(): Mission | undefined {
    return this.getSampleSite()?.getMission();
  }

  getBarcodeRegex(): string {
    const spec = this.getSamplingSpec();
    if (!spec) {
      return '';
    }

    return spec.barcode_regex || '';
  }

  static findMissionSampleBySampleID(mission: Mission, sampleID: string): Sample | undefined {
    return Sample.findOne((samp) => {
      if (!samp) {
        return false;
      }

      if (samp.sample_id !== sampleID) {
        return false;
      }

      const sampleMission = samp.getMission();
      if (!sampleMission) {
        return false;
      }

      return sampleMission.instance_id === mission.instance_id;
    });
  }

  static getSampleTypeByPoint(point: IKMLElement) {
    // Get the sample type from the parent folder
    const parent = point.findParent('Folder', (sel) => {
      const nameElement = sel?.find('name');
      if (!nameElement) return false;
      return ['ISNT 1', 'ISNT 2', 'Cyst'].includes(nameElement.getText());
    });

    if (!parent) {
      return SamplingTypes.REGULAR;
    }

    let samplingType = SamplingTypes.REGULAR;
    const name = parent.find('name')?.getText();
    if (name === 'ISNT 1') {
      samplingType = SamplingTypes.ISNT_1;
    } else if (name === 'ISNT 2') {
      samplingType = SamplingTypes.ISNT_2;
    } else if (name === 'Cyst') {
      samplingType = SamplingTypes.CYST;
    }

    return samplingType;
  }

  static createSample(
    mission: Mission,
    sampleSite: SampleSite,
    samplingSpec: SamplingSpec,
    sampleID: string,
    sampleType: SamplingTypes,
  ) {
    const sample = Sample.create();
    sample.SampleSite_id = sampleSite.instance_id;
    sample.SamplingSpec_id = samplingSpec.instance_id;
    sample.sample_id = sampleID;
    sample.sample_type = sampleType;

    sample.order =
      Math.max(
        ...cleanSet(flatten(mission.getSampleSites().map((site) => site.getSamples()))).map(
          (sel: { order: any }) => sel.order,
        ),
      ) + 1;

    return sample;
  }

  static getSamplesByOriginalSampleID(mission: Mission, originalSampleID: string): Sample[] {
    const sampleSite = SampleSite.findByOriginalSampleID(mission, originalSampleID);
    if (!sampleSite) {
      return [];
    }

    return sampleSite.getSamples();
  }
}

export class SampleList extends DataObjectList<Sample> {
  getSampleSites() {
    return new SampleSiteList(...this.map((sample) => sample.getSampleSite()).filter((sel) => !!sel));
  }

  getSamplingSpecs() {
    return new SamplingSpecList(...this.map((sample) => sample.getSamplingSpec()).filter((sel) => !!sel));
  }

  getSoilCores() {
    return new SoilCoreList(
      ...this.reduce((soilCores: SoilCore[], sample) => soilCores.concat(sample.getSoilCores()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getSampleBoxes() {
    return new SampleBoxList(...this.map((sample) => sample.getSampleBox()).filter((sel) => !!sel));
  }
}
