import { Coordinate } from 'ol/coordinate';
import { SamplingMapStateStorage } from '../components/map/SamplingMapStateStorage';
import { CORE_DEADBAND_WIDTH_M, MS_PER_MIN } from '../constants';
import { getCurrentSession, getCurrentMission } from '../dataModelHelpers';
import { Sample, SoilCore, Waypoint } from '../db';
import {
  BigFieldMode,
  BlockCoringInSampleDeadband,
  BlockCoringLogicEnabled,
  BlockCoringMissingBarcode,
  BlockCoringMissingEntranceInterview,
  BlockCoringSoilMixRisk,
  MapCalculationPositionStorage,
  MapNearbyAutoZoomEnabled,
  MapZoomLevelNearSampleStorage,
  MapZoomLevelNotNearSampleStorage,
  MapZoomStorage,
} from '../db/local_storage';
import EventBus from '../EventBus';
import logger from '../logger';
import { dispatchSetZoom, MAP_EVENTS } from '../mapEvents';
import { dispatchRosMsgPub } from '../RobotConnection';
import { SCAN_EVENTS } from '../scanEvents';
import { RosPosition } from '../types/rosmsg';
import { CoringAllowedStates, CoringAllowedState, RobotNavControl } from '../types/types';
import { rosPositionToCoordinates } from '../utilities/rosUtils';
import { coordsEqual, distanceLatLon, LocalStorageGenerator } from '../utils';
import { calculateArmPosition } from './RobotArmService';
import { ClosestCore } from '../db/types';

export function canHandlePositionUpdate(hostname: string) {
  const session = getCurrentSession();
  const mission = getCurrentMission();
  const hostnameMismatch = hostname !== session?.robot_hostname;
  const bigFieldSkip = shouldExitBigFieldMode();
  if (!session || !mission || hostnameMismatch) {
    updateCoreAllowedState('Good');

    return false;
  }

  // if this is a big field, we will skip the position update and we don't
  // want to update the core allowed state
  if (bigFieldSkip) {
    return false;
  }

  return true;
}

export function updateCoreAllowedState(state: CoringAllowedState) {
  const session = getCurrentSession();
  if (!session) {
    return;
  }

  dispatchRosMsgPub({
    hostname: session.robot_hostname,
    msg: { data: CoringAllowedStates.indexOf(state) },
    topic: 'app/core_allow',
    tag: 'TargetCoordinateService',
  });

  EventBus.dispatch(SCAN_EVENTS.BARCODE_SCAN_STATE, state);
}

export function calculateOrigin(position: RosPosition) {
  const armPosition = calculateArmPosition([position.y, position.x], -position.z, '3857');
  const coordinate = MapCalculationPositionStorage.get() === 'Arm' ? armPosition : rosPositionToCoordinates(position);
  return coordinate;
}

export function shouldExitBigFieldMode() {
  if (BigFieldMode.get()) {
    // right now position updates get sent at ~10hz
    // as a workaround hack, we can basically just ignore any updates where the ms number doesn't
    // start with 1 in the date
    // this will effectively slow down the updates to 1hz
    const now = Date.now();
    const ms = parseInt(now.toString().substring(10));
    if (ms <= 100) {
      return true;
    }
  }

  return false;
}

function calcDistanceToClosestPulledCore(closestCores: ClosestCore[]): number | undefined {
  const closestPulledCores = closestCores.filter((core) => core[0].pulled);
  if (closestPulledCores.length === 0) {
    return undefined;
  }

  return closestPulledCores[0][1];
}

function chooseDistanceBase(closestCores: ClosestCore[], distanceToTargetMeters: number) {
  let distanceToClosestTakenCore = calcDistanceToClosestPulledCore(closestCores);
  if (typeof distanceToClosestTakenCore === 'undefined') {
    return distanceToTargetMeters;
  }

  return Math.min(distanceToTargetMeters, distanceToClosestTakenCore);
}

export const TargetOrderPreferences = [
  'Close -> Waypoint -> Unscanned',
  'Close -> Unscanned -> Waypoint',
  'Waypoint -> Close -> Unscanned',
] as const;
export type TargetOrderPreference = (typeof TargetOrderPreferences)[number];

export const TargetOrderPreferenceStorage = LocalStorageGenerator<TargetOrderPreference>(
  'TargetOrderPreference',
  TargetOrderPreferences[0],
);

export function determineTargetSample(
  closeSample: Sample | undefined,
  waypointTargetSample: Sample | undefined,
  nextUnscannedSample: Sample | undefined,
) {
  const firstTargetPreference = TargetOrderPreferenceStorage.get();
  if (firstTargetPreference === 'Close -> Waypoint -> Unscanned') {
    return closeSample || waypointTargetSample || nextUnscannedSample;
  } else if (firstTargetPreference === 'Close -> Unscanned -> Waypoint') {
    return closeSample || nextUnscannedSample || waypointTargetSample;
  } else {
    return waypointTargetSample || closeSample || nextUnscannedSample;
  }
}

export function determineTargetCoordinate(
  origin: Coordinate,
  targetSample: Sample | undefined,
): Coordinate | undefined {
  if (!targetSample) {
    return undefined;
  }

  const targetCore: SoilCore | undefined = targetSample.getNextSoilCoreTarget(origin);
  if (targetCore) {
    return targetCore.getCoordinates();
  }

  // if there are no cores, target the centroid.
  return targetSample.getSampleSite()?.getSampleCentroid()?.getCoordinates();
}

function inDeadband(
  distance: number | undefined,
  closeSample: Sample | undefined,
  closestCores: ClosestCore[],
  presentToleranceMeters: number,
  robotNavControlState: RobotNavControl,
  strictCoreEnforcement: boolean,
) {
  const coringDeadbandMeters = presentToleranceMeters + CORE_DEADBAND_WIDTH_M;

  if (distance && distance > coringDeadbandMeters) {
    return false;
  }

  // Deadband Checks...
  if (distance && distance > presentToleranceMeters && distance <= coringDeadbandMeters) {
    return true;
  }

  // new logic for manual mode...
  if (robotNavControlState === 'Manual' && strictCoreEnforcement && closeSample) {
    // if we are in manual mode, and NOT in the edge cores,
    const edgeUnpulledCores = closeSample.getEdgeUnpulledSoilCores();

    // filter 'close' cores to only include the edge unpulled cores that are close enough
    const closeUnpulledCores = closestCores.filter(
      (core) => edgeUnpulledCores.includes(core[0]) && core[1] <= presentToleranceMeters,
    );

    const edgeCoresCloseEnough = closeUnpulledCores.length > 0;
    if (!edgeCoresCloseEnough) {
      // if we are in manual mode, and NOT in the edge cores, then we will reuse the sample deadband property
      // we are also re-using this state, because the robot will ignore it in Auto mode, so it's also a safe option
      return true;
    }
  }

  return false;
}

function determineTargets(
  origin: Coordinate,
  closeCores: ClosestCore[],
  waypointTargetSample: Sample | undefined,
  closeSampleToleranceMeters: number,
  presentToleranceMeters: number,
) {
  const mission = getCurrentMission();

  let presentSample: Sample | undefined = undefined;
  let closeSample: Sample | undefined = undefined;

  // 2. determine the 'close' sample based on origin the position of the robot
  closeSample = closeCores.length > 0 ? closeCores[0][0]?.getSample() : undefined;

  // 3. determine the target. The target is used to determine if we are close enough to be "present"
  const nextUnscannedSample = mission?.getNextUnpulledSample();
  const targetSample = determineTargetSample(closeSample, waypointTargetSample, nextUnscannedSample);
  const targetCoordinate = targetSample ? determineTargetCoordinate(origin, targetSample) : undefined;

  let distanceToTargetMeters = targetCoordinate
    ? distanceLatLon(origin[0], origin[1], targetCoordinate[0], targetCoordinate[1], 'Kilometers') * 1000
    : Number.MAX_SAFE_INTEGER;

  // are we close enough to the sample to be "present"?
  // scenario 1: Close enough to be "present"
  if (distanceToTargetMeters <= presentToleranceMeters) {
    closeSample = targetSample;
    presentSample = targetSample;
  }
  // scenario 2: Close enough to be "approaching"
  else if (distanceToTargetMeters <= closeSampleToleranceMeters) {
    closeSample = targetSample;
    // if we are close but we weren't near, then we will check if we are in the coring deadband
  } else {
    // scenario 3: Not close enough to be "approaching" or "present"
  }

  return {
    closeSample,
    presentSample,
    distanceToTargetMeters,
    targetCoordinate,
  };
}

export const isCloseEnoughToCoreInZoneMission = (
  origin: Coordinate,
  closeCores: ClosestCore[],
  presentToleranceMeters: number,
): boolean => {
  if (!BlockCoringInSampleDeadband.get()) {
    return true;
  }

  const closestCore = closeCores.length > 0 ? closeCores[0][0] : undefined;

  // We will allow coring if we are close enough to any core of the current sample.
  // This way, we let the operators retake already pulled cores. We will replace them with new ones.
  const closestCoreCoordinate = closestCore?.getCoordinates();
  const distanceToAnyCoreOfCurrentSample = closestCoreCoordinate
    ? distanceLatLon(origin[0], origin[1], closestCoreCoordinate[0], closestCoreCoordinate[1], 'Kilometers') * 1000
    : Number.MAX_SAFE_INTEGER;

  return distanceToAnyCoreOfCurrentSample <= presentToleranceMeters;
};

function closeEnoughToCore(
  robotNavControlState: RobotNavControl,
  inSampleDeadband: boolean,
  isZoneMission: boolean,
  closeEnoughToCoreInZoneMission: boolean,
): boolean {
  if (robotNavControlState === 'Auto') {
    return true;
  }

  if (!BlockCoringInSampleDeadband.get()) {
    return true;
  }

  if (inSampleDeadband) {
    return false;
  }

  if (isZoneMission) {
    return closeEnoughToCoreInZoneMission;
  }

  return true;
}

export function calculateSampleUpdates(
  origin: Coordinate,
  currentWaypoint: Waypoint | undefined,
  robotNavControlState: RobotNavControl,
  soilInBucket: boolean,
  lastCloseSample: Sample | undefined,
  lastPresentSample: Sample | undefined,
  lastTargetCoordinate: Coordinate | undefined,
  isInBulkDensityMode: boolean,
  coringAllowedState: CoringAllowedState,
  strictCoreEnforcement: boolean,
) {
  /*
    ┌──────────────────────────────┐
    │  ┌────────────────────────┐  │
    │  │    ┌──────────────┐    │  │
    │  │    │   Present    │    │  │
    │  │    └──────────────┘    │  │
    │  │        Deadband        │  │
    │  └────────────────────────┘  │
    │         Nearby/Close         │
    └──────────────────────────────┘
    Present is a very special designation that we are actually in range to take a valid core and get a sample
    Value: Mission tolerance OR default if not available
  
    The deadband is a region where we are close enough to be considered to be "coring" but not close enough to be "present"
    we will disallow coring in this region to ensure the cores are counted towards the sample
    Value: Present tolerance + 15 ft
  
    Nearby is a region where we are close enough to be considered to be "approaching" but not close enough to be "coring"
    We will use this region to do auto zooming and other things to help the operator get to the sample
    As such, this region will have a minimum size based on the size needed to perform a zoom
    Value: 50 ft outside of core
    */

  const session = getCurrentSession();
  const mission = getCurrentMission();

  if (!mission) {
    return;
  }

  const [closeSampleToleranceMeters, presentToleranceMeters] = mission.get_close_present_tolerance();

  let presentSample: Sample | undefined = undefined;
  let closeSample: Sample | undefined = undefined;
  // target option 1: waypoint?
  const waypointTargetSample = currentWaypoint?.getRelatedSample();

  let inSampleDeadband = false;

  const closeCores = mission.getCloseCores2(origin, closeSampleToleranceMeters);

  let targetCoordinate: Coordinate | undefined = undefined;

  let closeEnoughToCoreInZoneMission = false;
  const isZoneMission = mission.is_zone_mission();
  // 1a: if we are in a zone mission, we will just use the zone sample
  if (isZoneMission) {
    const zone = mission.getEnclosedSampleZone(origin);
    if (zone) {
      presentSample = zone.getSampleSite()?.getSamples()[0];
      closeSample = presentSample;
    }

    const closeUnpulledCores = closeCores.filter((core) => !core[0].pulled);
    const closestUnpulledCore = closeUnpulledCores.length > 0 ? closeUnpulledCores[0][0] : undefined;

    // We'll set the targetCoordinate to the closest unpulled core.
    targetCoordinate = closestUnpulledCore?.getCoordinates();
    closeEnoughToCoreInZoneMission = isCloseEnoughToCoreInZoneMission(origin, closeCores, presentToleranceMeters);

    // 1b: if we are in a grid or modgrid mission, we will use the waypoint target sample
  } else {
    const targets = determineTargets(
      origin,
      closeCores,
      waypointTargetSample,
      closeSampleToleranceMeters,
      presentToleranceMeters,
    );
    presentSample = targets.presentSample;
    closeSample = targets.closeSample;

    targetCoordinate = targets.targetCoordinate;

    inSampleDeadband = inDeadband(
      targets.distanceToTargetMeters,
      closeSample,
      closeCores,
      presentToleranceMeters,
      robotNavControlState,
      strictCoreEnforcement,
    );
  }

  // log the event bus reporting every 10 minutes
  logger.logPeriodic('EVENT_BUS_REPORTING', `${EventBus.stats()}`, 10 * MS_PER_MIN);

  const coresInBucket = mission.getSoilCoresInBucket();

  let state: CoringAllowedState = 'Good';
  const presentAtSample = !!presentSample;
  const selectedSample = session?.getSample();
  const sampleIsSelected = !!selectedSample;
  const presentSampleMatchesSelectedSample = presentSample?.sample_id === selectedSample?.sample_id;
  const haveSampleCoresInBucket = !!coresInBucket.length;
  const haveTestCoresInBucket = coresInBucket.some((core) => !!core.test_core_Mission_id);

  const coresInBucketSampleId = haveSampleCoresInBucket ? coresInBucket[coresInBucket.length - 1].sample_id : '';
  const atDifferentSampleId = coresInBucketSampleId && presentSample?.sample_id !== coresInBucketSampleId;
  const haveCoresAndPresentAtDifferentId = (presentSample && haveTestCoresInBucket) || atDifferentSampleId;

  const selectedSampleHasBarcode = selectedSample?.bag_id;
  const missingBarcode = sampleIsSelected && !selectedSampleHasBarcode && !presentSampleMatchesSelectedSample;
  const entranceInterviewComplete = mission.getJob()?.entrance_interview_complete;

  // this logic is tricky because we need to have a heirarchy of the most important issues
  // however, any one of these should be logically independent of the others, so we will
  // enable a flag for each condition in case we need to enable or disable any specific
  // logic in the field

  // 1: Is the entrance interview complete? <- No coring can start until this is true so the other states should be moot
  // 2: Do we have a soil mix risk?         <- If we have the entrance interview complete, this is the next most important
  //                                           Technically in this state we are very likely not close enough to a sample
  //                                           we are in some ways still missing a barcode too, but we need them to know it's a
  //                                           soil mix risk most importantly
  // 3: Are we missing a barcode?           <- As long as we don't have a soil mix risk, we can see if we have a selected sample
  //                                           that isn't scanned
  // 4: Are we not near a sample?           <- If we don't have a selected sample missing a barcode, we don't want to core unless
  //    OR in manual and not in unpulled       we are near a sample
  //     edge core?

  if (!entranceInterviewComplete && presentAtSample && BlockCoringMissingEntranceInterview.get()) {
    state = 'Need Field Info';
  } else if (haveSoilMixRisk(isInBulkDensityMode, haveCoresAndPresentAtDifferentId)) {
    state = 'Soil Mix Risk';
  } else if (missingBarcode && BlockCoringMissingBarcode.get()) {
    state = 'Missing Barcode';
  } else if (
    !closeEnoughToCore(robotNavControlState, inSampleDeadband, isZoneMission, closeEnoughToCoreInZoneMission)
  ) {
    state = 'Not Close Enough';
  }

  if (!BlockCoringLogicEnabled.get()) {
    state = 'Good';
  }

  updateCoreAllowedState(state);

  // This would be true if we don't have a present sample, or if the present sample hasn't changed
  const presentSampleChanged = lastPresentSample !== presentSample;
  const closeSampleChanged = lastCloseSample !== closeSample;
  const coringAllowedStateChanged = coringAllowedState !== state;
  const targetCoordinateChanged = !coordsEqual(lastTargetCoordinate, targetCoordinate);

  const noStateChanges =
    !presentSampleChanged && !closeSampleChanged && !targetCoordinateChanged && !coringAllowedStateChanged;

  if (noStateChanges) {
    return;
  }

  if (coringAllowedStateChanged) {
    logger.log('CORING_ALLOWED_STATE_CHANGED', {
      stateBefore: coringAllowedState,
      stateAfter: state,
      isInBulkDensityMode,
      coresInBucketLength: coresInBucket.length,
      soilInBucket,
      haveSampleCoresInBucket,
      haveTestCoresInBucket,
      presentSampleId: presentSample?.sample_id,
      coresInBucketSampleId,
      atDifferentSampleId,
      haveCoresAndPresentAtDifferentId,
    });
  }

  // TODO replace with a measuremnt from above? (close + 5 ft?)
  if (presentSampleChanged) {
    if (presentSample) {
      EventBus.dispatch(MAP_EVENTS.SAMPLE_ARRIVED, presentSample.sample_id);
    } else {
      EventBus.dispatch(MAP_EVENTS.SAMPLE_LEFT, lastPresentSample?.sample_id);
    }
  }

  if (closeSampleChanged) {
    if (closeSample) {
      EventBus.dispatch(MAP_EVENTS.CLOSE_SAMPLE_ARRIVED, closeSample.sample_id);
    } else {
      EventBus.dispatch(MAP_EVENTS.CLOSE_SAMPLE_LEFT, lastCloseSample?.sample_id);
    }
  }

  const autoZoomEnabled = MapNearbyAutoZoomEnabled.get();
  const haveCloseSample = !!closeSample;
  const haveWaypointSample = !!waypointTargetSample;
  const closeSampleIsWaypointSample =
    haveCloseSample && haveWaypointSample && closeSample?.sample_id === waypointTargetSample?.sample_id;
  const trackingRobotPosition = SamplingMapStateStorage.get().tracking;
  const shouldAutoZoom = autoZoomEnabled && trackingRobotPosition && closeSampleIsWaypointSample;

  if (closeSampleChanged && shouldAutoZoom) {
    if (closeSample) {
      // we will save the current user zoom level so when we zoom back out we will return them
      // to the same zoom level they were at before the auto zoom
      MapZoomLevelNotNearSampleStorage.set(MapZoomStorage.get().value);
      dispatchSetZoom(MapZoomLevelNearSampleStorage.get());
    } else {
      dispatchSetZoom(MapZoomLevelNotNearSampleStorage.get());
    }
  }

  // right now, we will only set this state when the sample we are at CHANGES.
  // as such, even though we constantly do calcualtions on this logic, we
  // will only cause re-renders of the sampling stack when we are actually at a
  // different sample
  return {
    presentSample,
    closeSample,
    inSampleDeadband,
    targetCoordinate,
    coringAllowedState: state,
  };
}

function haveSoilMixRisk(isInBulkDensityMode: boolean, haveCoresAndPresentAtDifferentId: string | boolean | undefined) {
  if (!BlockCoringSoilMixRisk.get()) {
    return false;
  }

  if (isInBulkDensityMode) {
    return false;
  }

  return haveCoresAndPresentAtDifferentId;
}
